/* Base styles */
/* .our-leadership {
  display: grid;
  gap: 3rem;
  grid-template-columns: 30% 70%;
  margin-top: 1rem;
} */

.title-text {
  font-family: "Lato !important";
  font-size: 72px;
  font-weight: 800;
  line-height: 86px;
}

.faq-sub-header-text {
  color: #0a0a0a;
  font-family: "Abhaya Libre ExtraBold !important";
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  text-transform: uppercase;
}

.faq-header-text {
  color: #0a0a0a;
  font-family: "Abhaya Libre ExtraBold !important";
  font-size: 48px;
  font-weight: 800;
  line-height: 60px;
  letter-spacing: 1%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  /* .our-leadership {
    grid-template-columns: 100%;
  } */

  .header-text {
    font-size: 52px;
    font-weight: 800;
    line-height: 86px;
  }
}

@media (max-width: 576px) {
  .header-text {
    font-size: 42px;
    font-weight: 800;
    line-height: 86px;
  }

  .faq-sub-header-text {
    color: #0a0a0a;
    font-family: "Abhaya Libre ExtraBold !important";
    font-size: 18px;
    font-weight: 800;
    line-height: 26px;
    text-transform: uppercase;
  }

  .faq-header-text {
    color: #0a0a0a;
    font-family: "Abhaya Libre ExtraBold !important";
    font-size: 42px;
    font-weight: 800;
    line-height: 42px;
    letter-spacing: 1%;
  }
}
