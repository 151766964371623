/* Base styles for text elements */
.contact-us-header-text,
.contact-us-sub-header-text {
  font-family: "Abhaya Libre ExtraBold", serif;
  font-weight: 800;
  color: #fff !important;
}

.header-text {
  font-size: 48px;
  line-height: 60px;
  letter-spacing: 1%;
  vertical-align: middle;
}

.sub-header-text {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0%;
  text-transform: uppercase;
}

.contact-us-text {
  display: flex;
  flex-direction: column;
  padding-top: 3%;
  margin-left: 288.55px;
}

.contact-us-grid {
    display: grid;
    gap: 3rem;
    grid-template-columns: 50% 50%;
  }

/* Responsive adjustments */
@media (max-width: 768px) {
  .contact-us-text {
    margin-left: 100px;
  }
}

@media (max-width: 576px) {
  .header-text {
    font-size: 28px;
    line-height: 30px;
  }

  .sub-header-text {
    font-size: 18px;
  }

  .contact-us-grid {
    margin-top: 3rem;
    display: grid;
    gap: 3rem;
    grid-template-columns: 100%;
  }

  .contact-us-text {
    margin-left: 20px;
    margin-right: 20px;
  }

  .contact-us-form {
    width: 90%;
  }
}