/* Responsive grid for different screen sizes */
.about-us-grid {
  display: grid;
  margin-top: 1rem;
  gap: 0.5rem;
  grid-template-columns: 50% 50%;
}

@media (max-width: 576px) {
  .about-us-grid {
    grid-template-columns: 100%;
  }
}