/* Responsive grid for different screen sizes */
@media (max-width: 1200px) {
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
  .social-icon {
    width: 10%;
  }
}
