/* Base styles */
.our-leadership {
  display: grid;
  gap: 3rem;
  grid-template-columns: 30% 70%;
  margin-top: 1rem;
}

.header-text {
  font-family: "Abhaya Libre ExtraBold", serif;
  font-weight: 800;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: 1%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .our-leadership {
    grid-template-columns: 100%;
  }

  .header-text {
    font-size: 36px;
    line-height: 48px;
  }
}

@media (max-width: 576px) {
  .header-text {
    font-size: 24px;
    line-height: 32px;
  }
}
