/* Base styles for header text */
.header-text {
  font-family: "Abhaya Libre ExtraBold", serif;
  font-weight: 800;
  font-size: 48px;
  line-height: 45px;
}

/* Base styles for gallery grids */
.gallery-grid-1,
.gallery-grid-2 {
  margin-top: 1rem;
  display: grid;
  gap: 0.5rem;
}

.gallery-grid-1 {
  grid-template-columns: 50% 25% 25%;
}

.gallery-grid-2 {
  grid-template-columns: 25% 50% 25%;
}

/* Mobile responsive styles */
@media (max-width: 576px) {
  .header-text {
    color: #0A0A0A !important;
    font-size: 38px;
    line-height: 35px;
  }

  .sub-header-text {
    color: #0a0a0a !important;
    font-family: "Abhaya Libre ExtraBold", serif;
    font-weight: 800;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0;
    text-transform: uppercase;
  }

  .gallery-grid-1,
  .gallery-grid-2 {
    grid-template-columns: 100%;
  }
}