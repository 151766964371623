/* Responsive grid for different screen sizes */
.affilate-program-section {
  display: grid;
  margin-top: 1rem;
  gap: 0.5rem;
  grid-template-columns: 50% 50%;
}

.header-text {
  font-family: "Abhaya Libre ExtraBold", serif;
  font-weight: 700;
  font-size: 52px;
  line-height: 56px;
}

@media (max-width: 768px) {
  .header-text {
    font-size: 52px;
    line-height: 56px;
  }
}

@media (max-width: 576px) {
  .affilate-program-section {
    gap: 3rem;
    grid-template-columns: 100%;
  }

  .header-text {
    font-size: 32px;
    line-height: 36px;
  }
}