/* Responsive grid for different screen sizes */
@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {

}

@media (max-width: 576px) {
  .header-text {
    font-size: "48px";
    font-weight: "800";
    line-height: "35px";
    font-family: "Abhaya Libre ExtraBold";
  }

}