/* Responsive grid for different screen sizes */
@media (max-width: 1200px) {
.header-text {
    font-size: "48px";
    font-weight: "800";
    line-height: "45px";
    font-family: "Abhaya Libre ExtraBold";
  }

  .services-grid {
    margin-top: 1rem;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 50% 50%;
  }
}

@media (max-width: 992px) {
.header-text {
    font-size: "48px";
    font-weight: "800";
    line-height: "45px";
    font-family: "Abhaya Libre ExtraBold";
  }

  .services-grid {
    margin-top: 1rem;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 50% 50%;
  }
}

@media (max-width: 768px) {
.header-text {
    font-size: "48px";
    font-weight: "800";
    line-height: "45px";
    font-family: "Abhaya Libre ExtraBold";
  }

  .services-grid {
    margin-top: 1rem;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 50% 50%;
  }
}

@media (max-width: 576px) {
  .header-text {
    font-size: "38px";
    font-weight: "800";
    line-height: "35px";
    font-family: "Abhaya Libre ExtraBold";
  }

  .services-grid {
    margin-top: 1rem;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 100%;
  }
}